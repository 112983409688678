import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import { Icon } from 'src/types';

import LoadingIndicator from './LoadingIndicator';

type BaseProps = {
  color?: 'primary' | 'dark' | 'gray' | 'white' | 'green' | 'orange' | 'red';
  icon: Icon;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
};

type ButtonProps = BaseProps & {
  as?: 'button';
  type?: 'button' | 'submit';
  ref?: React.Ref<HTMLButtonElement>;
  role?: string;
  ariaChecked?: boolean;
  onClick?: () => void;
};

type LinkProps = BaseProps & {
  as: 'link';
  href?: string;
  openInNewWindow?: boolean;
  onClick?: () => void;
};

const IconButton = (props: ButtonProps | LinkProps) => {
  const { color, loading, disabled, className } = props;

  const classes = baseClasses({
    color,
    loading,
    disabled,
    className,
  });

  if (props.as === 'link') {
    const { onClick, href, openInNewWindow } = props;

    return (
      <a
        className={classes}
        href={href}
        target={openInNewWindow ? '_blank' : undefined}
        rel="noreferrer"
        onClick={onClick}
      >
        <Content {...props} />
      </a>
    );
  }

  const { onClick, type = 'button', role, ariaChecked } = props;

  return (
    <button
      type={type}
      className={classes}
      onClick={onClick}
      disabled={disabled}
      role={role}
      aria-checked={ariaChecked ? 'true' : 'false'}
    >
      <Content {...props} />
    </button>
  );
};

const Content = ({ color, icon: Icon, loading, children }: BaseProps) => (
  <span className="relative block">
    <LoadingIndicator
      className={classNames(
        'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300',
        {
          'opacity-0': !loading,
          'opacity-100': loading,
        }
      )}
      color={color === 'white' || color === 'gray' ? 'dark' : 'white'}
    />
    <span
      className={classNames('flex justify-center transition-opacity duration-300', {
        'opacity-100': !loading,
        'opacity-0': loading,
      })}
    >
      <Icon className="text-xl" />
      {children && <span className="sr-only">{children}</span>}
    </span>
  </span>
);

const baseClasses = cva('rounded-xl p-2.5 transition-colors', {
  variants: {
    color: {
      dark: 'bg-gray-900 text-white disabled:bg-gray-700 disabled:text-gray-200',
      gray: 'bg-gray-200 text-gray-900 disabled:bg-gray-200 disabled:text-gray-500',
      white: 'bg-white text-gray-900 disabled:bg-white disabled:text-gray-500',
      primary: 'bg-primary-500 text-white disabled:bg-primary-300 disabled:text-primary-100',
      green: 'bg-green-500 text-white disabled:bg-green-300 disabled:text-green-100',
      orange: 'bg-orange-500 text-white disabled:bg-orange-300 disabled:text-orange-100',
      red: 'bg-red-500 text-white disabled:bg-red-300 disabled:text-red-100',
    },
    loading: {
      true: 'cursor-default',
      false: null,
    },
    disabled: {
      true: 'cursor-default',
      false: null,
    },
  },
  compoundVariants: [
    {
      loading: false,
      disabled: false,
      color: 'dark',
      className: 'hover:bg-gray-800',
    },
    {
      loading: false,
      disabled: false,
      color: 'gray',
      className: 'hover:bg-gray-300',
    },
    {
      loading: false,
      disabled: false,
      color: 'white',
      className: 'hover:bg-gray-200',
    },
    {
      loading: false,
      disabled: false,
      color: 'primary',
      className: 'hover:bg-primary-600',
    },
    {
      loading: false,
      disabled: false,
      color: 'green',
      className: 'hover:bg-green-600',
    },
    {
      loading: false,
      disabled: false,
      color: 'orange',
      className: 'hover:bg-orange-600',
    },
    {
      loading: false,
      disabled: false,
      color: 'red',
      className: 'hover:bg-red-600',
    },
  ],
  defaultVariants: {
    color: 'dark',
    loading: false,
    disabled: false,
  },
});

export default IconButton;
