import classNames from 'classnames';
import { Eye, EyeOff } from 'lib/components/misc/icons';
import { useTranslation } from 'lib/translation/index';
import { forwardRef, HTMLProps, Ref, useState } from 'react';

import IconButton from '../IconButton';
import FieldErrorMessage from './FieldErrorMessage';
import Label from './Label';

type PasswordInputProps = Omit<HTMLProps<HTMLInputElement>, 'type'> & {
  name: string;
  label?: string;
  fieldError?: string;
};

const PasswordInput = (
  { id, name, label, required = false, className, fieldError, ...inputProps }: PasswordInputProps,
  ref: Ref<HTMLInputElement>
) => {
  const inputId = id || `input-${name}`;
  const [showPassword, setShowPassword] = useState(false);
  const { translate } = useTranslation();

  return (
    <div className={classNames('relative mt-8', className)}>
      <input
        id={inputId}
        ref={ref}
        name={name}
        type={showPassword ? 'text' : 'password'}
        className="peer w-full rounded-xl bg-gray-200 py-2.5 pl-4 pr-12 text-lg font-semibold text-gray-900 placeholder:opacity-0 focus-visible:outline-none disabled:cursor-default"
        aria-invalid={!!fieldError}
        placeholder={label}
        {...inputProps}
      />
      {label && (
        <Label htmlFor={inputId} required={required}>
          {label}
        </Label>
      )}
      <IconButton
        className={classNames('absolute top-1 right-1.5 rounded-xl bg-transparent p-2 hover:bg-gray-300', {
          'text-gray-500': !showPassword,
          'text-gray-900': showPassword,
        })}
        color="gray"
        icon={showPassword ? Eye : EyeOff}
        role="switch"
        ariaChecked={showPassword}
        onClick={() => setShowPassword((v) => !v)}
      >
        {translate(showPassword ? 'form.password.hide' : 'form.password.show')}
      </IconButton>

      {fieldError && <FieldErrorMessage message={fieldError} />}
    </div>
  );
};

export default forwardRef(PasswordInput);
