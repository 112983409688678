import { zodResolver } from '@hookform/resolvers/zod';
import { Game, GameStatus, QuestionType, Slot, SlotType } from '@prisma/client';
import { ErrorBoundary } from '@sentry/nextjs';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import FormInput from 'lib/components/form/FormInput';
import FormPasswordInput from 'lib/components/form/FormPasswordInput';
import Container from 'lib/components/misc/Container';
import { ArrowRight, LockUnlocked01 } from 'lib/components/misc/icons';
import ChoicePartial from 'lib/components/question/ChoicePartial';
import ClosestPartial from 'lib/components/question/ClosestPartial';
import GeoPartial from 'lib/components/question/GeoPartial';
import OpenPartial from 'lib/components/question/OpenPartial';
import UnscramblePartial from 'lib/components/question/UnscramblePartial';
import Button from 'lib/components/ui/Button';
import CountdownRoll from 'lib/components/ui/CharacterRoll/CountdownRoll';
import Headline from 'lib/components/ui/Headline';
import LoadingIndicator from 'lib/components/ui/LoadingIndicator';
import { LogoStacked } from 'lib/components/ui/Logo';
import Paragraph from 'lib/components/ui/Paragraph';
import { parsePrismaJson } from 'lib/helper/data';
import { TimerProvider, useTimerContext } from 'lib/hooks/timer';
import { useTranslation } from 'lib/translation';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import CountUp from 'react-countup';
import { FormProvider, useForm } from 'react-hook-form';
import { GameContentProps, GivenAnswer } from 'src/types';
import { api } from 'utils/client';
import { ErrorCode } from 'utils/errorCodes';
import { z } from 'zod';

const passwordFormSchema = z.object({ password: z.string().min(1) });
type PasswordFormData = z.infer<typeof passwordFormSchema>;

const createPlayerFormSchema = z.object({
  name: z.string().regex(/^[a-zA-Z0-9]{2,24}$/, { message: 'invalidName' }),
});

type CreatePlayerFormData = z.infer<typeof createPlayerFormSchema>;

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 1 } },
  exit: { opacity: 0 },
};

const Game = () => {
  const router = useRouter();

  return <ErrorBoundary>{router.query.nanoId && <GameFinder nanoId={router.query.nanoId as string} />}</ErrorBoundary>;
};

const GameFinder = ({ nanoId }: { nanoId: string }) => {
  const { data, isLoading } = api.player.gameByNanoId.useQuery({ nanoId });

  if (isLoading) {
    return (
      <Container className="py-12 px-4" mobile>
        <div className="flex h-24 items-center justify-center">
          <LoadingIndicator size={24} color="primary" />
        </div>
      </Container>
    );
  }

  if (!data) {
    return (
      <Container className="py-12 px-4" mobile>
        <div className="flex h-24 items-center justify-center">
          {/* // TODO: add proper error message, with "back to home" button, or maybe 404 */}
          <p>Game not found</p>
        </div>
      </Container>
    );
  }

  if (data.playerId) {
    return <GameContent {...data} playerId={data.playerId} />;
  }

  return <CreatePlayer {...data} nanoId={nanoId} />;
};

const CreatePlayer = ({
  id,
  title,
  passwordProtected,
  nanoId,
}: Pick<Game, 'id' | 'title'> & {
  passwordProtected: boolean;
  nanoId: string;
}) => {
  const posthog = usePostHog();
  const apiUtils = api.useContext();
  const [password, setPassword] = useState<string | undefined>();
  const { translate } = useTranslation();

  const formCtx = useForm({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(createPlayerFormSchema),
  });

  const { formState, handleSubmit, register, setError } = formCtx;

  const createPlayerMutation = api.player.create.useMutation({
    onSuccess: (data) => {
      posthog?.identify(data.name, {
        gameId: id,
        name: data.name,
      });
      apiUtils.player.gameByNanoId.invalidate({ nanoId });
    },
    onError: (error) => {
      if (error.message === ErrorCode.UniqueConstraintViolation) {
        setError('name', { type: 'custom', message: 'playerNameExists' });
      } else {
        setError('name', { type: 'custom', message: 'somethingWentWrong' });
      }
    },
  });

  const onSubmit = async ({ name }: CreatePlayerFormData) => {
    createPlayerMutation.mutate({ id, name, password });
  };

  return (
    <Container className="py-12 px-4" mobile>
      <LogoStacked className="mx-auto mb-12 w-64" animated />
      <Headline className="text-center" size="h3">
        {title}
      </Headline>
      {passwordProtected && !password ? (
        <PasswordPrompt id={id} onSuccess={setPassword} />
      ) : (
        <FormProvider {...formCtx}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput label={translate('name')} name="name" formState={formState} register={register} required />
            <Button className="mt-8" type="submit" block iconSuffix={ArrowRight}>
              {translate('action.join')}
            </Button>
          </form>
        </FormProvider>
      )}
    </Container>
  );
};

const PasswordPrompt = ({ id, onSuccess }: { id: Game['id']; onSuccess: (password: string) => void }) => {
  const { translate } = useTranslation();

  const formCtx = useForm({
    defaultValues: {
      password: '',
    },
    resolver: zodResolver(passwordFormSchema),
  });

  const { formState, handleSubmit, register, setError, getValues } = formCtx;

  const verifyPasswordMutation = api.game.verifyPassword.useMutation({
    onSuccess: (data) => {
      if (data.verified) {
        const password = getValues('password');

        onSuccess(password);
      }
    },
    onError: (error) => {
      if (error.message === ErrorCode.InvalidCredentials) {
        setError('password', { type: 'custom', message: 'invalidPassword' });
      }

      if (error.message === ErrorCode.SomethingWentWrong) {
        setError('password', { type: 'custom', message: 'somethingWentWrong' });
      }
    },
  });

  const onSubmit = async ({ password }: PasswordFormData) => {
    verifyPasswordMutation.mutate({ id, password });
  };

  return (
    <FormProvider {...formCtx}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paragraph>{translate('hint.passwordProtected')}</Paragraph>
        <FormPasswordInput
          label={translate('form.password.label')}
          name="password"
          formState={formState}
          register={register}
          required
        />
        <Button className="mt-8" type="submit" iconSuffix={LockUnlocked01} block>
          {translate('action.unlock')}
        </Button>
      </form>
    </FormProvider>
  );
};

type PlayerStateProps = {
  slot?: Slot['id'];
  timestamp?: number;
};

const GameContent = ({
  id,
  playerId,
}: Pick<Game, 'id' | 'title'> & {
  passwordProtected: boolean;
  playerId: string;
}) => {
  const apiUtils = api.useContext();
  const [status, setStatus] = useState<GameStatus>(GameStatus.Waiting);
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | undefined>();
  const [playerState, setPlayerState] = useState<PlayerStateProps | undefined>();
  const { data, isLoading } = api.player.getGame.useQuery(
    { id },
    {
      refetchInterval:
        process.env.NODE_ENV === 'production' && (status === GameStatus.Waiting || status === GameStatus.Running)
          ? 2000
          : false,
      refetchIntervalInBackground: true,
    }
  );
  const updatePlayerTimerState = api.player.timerState.useMutation();

  // TODO: get this from the game settings for each questionType
  const dummyTimeLimit = 10;
  const timeLimit = dummyTimeLimit + 1;

  api.game.subscribe.useSubscription(
    { id },
    {
      onData: () => {
        apiUtils.player.getGame.invalidate({ id });
      },
    }
  );

  useEffect(() => {
    if (!playerState) {
      const playerStateJson = localStorage.getItem(`player_state-${playerId}`);
      const parsedPlayerState = playerStateJson ? JSON.parse(playerStateJson) : undefined;

      if (parsedPlayerState) {
        setPlayerState(parsedPlayerState);
      }
    }

    if (!wakeLock && 'wakeLock' in navigator) {
      try {
        navigator.wakeLock.request('screen').then((lock) => {
          setWakeLock(lock);
        });
      } catch (error) {
        console.log('Wake Lock error', error);
      }
    }

    return () => {
      if (wakeLock) {
        wakeLock.release();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.status && data?.status !== status) {
      setStatus(data.status);
    }

    if (data?.activeSlot) {
      const activeSlot = parsePrismaJson(data.activeSlot);

      if (data.timerStarted && (activeSlot?.id !== playerState?.slot || !playerState)) {
        const newState = {
          slot: activeSlot?.id,
          timestamp: Date.now(),
        };

        setPlayerState(newState);
        localStorage.setItem(`player_state-${playerId}`, JSON.stringify(newState));

        updatePlayerTimerState.mutate({
          id: game.id,
          state: true,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return (
      <Container className="py-12 px-4" mobile>
        <div className="flex h-24 items-center justify-center">
          <LoadingIndicator size={24} color="primary" />
        </div>
      </Container>
    );
  }

  if (!data) {
    return <div className="flex h-24 items-center justify-center">No game data found</div>;
  }

  const activeSlot = parsePrismaJson(data.activeSlot);
  const game = { ...data, activeSlot };
  const player = game.players.find((player) => player.id === playerId);
  const answers = player?.answers && parsePrismaJson(player.answers);

  return (
    <AnimatePresence mode="wait" initial={false}>
      {data.status === GameStatus.Waiting && (
        <motion.div key="waiting" {...motionProps}>
          <WaitingPartial {...game} player={player} answers={answers} />
        </motion.div>
      )}
      {data.status === GameStatus.Running && (
        <motion.div key="running" {...motionProps}>
          <TimerProvider timestamp={playerState?.timestamp} timeLimit={timeLimit} started={data.timerStarted}>
            <RunningPartial {...game} player={player} answers={answers} />
          </TimerProvider>
        </motion.div>
      )}
      {data.status === GameStatus.Finished && (
        <motion.div key="finished" {...motionProps}>
          <FinishedPartialProvider {...game} player={player} answers={answers} />
        </motion.div>
      )}
      {data.status === GameStatus.Aborted && (
        <motion.div key="aborted" {...motionProps}>
          <AbortedPartial />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const WaitingPartial = ({ player, title, scheduledDate, players }: GameContentProps) => {
  const { translate } = useTranslation();
  const [difference, setDifference] = useState<number | null>(
    scheduledDate ? scheduledDate.getTime() - new Date().getTime() : null
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!scheduledDate) return;

    const newDifference = scheduledDate.getTime() - new Date().getTime();

    interval = setInterval(() => {
      if (newDifference < 0) {
        clearInterval(interval);
        setDifference(null);
        return;
      }

      setDifference(newDifference);
    }, 1000);

    return () => clearInterval(interval);
  }, [scheduledDate, difference]);

  return (
    <Container className="py-12 px-4" mobile>
      <LogoStacked className="mx-auto mb-12 w-64" animated />
      <Headline className="mb-8 text-center" size="h2">
        {title}
      </Headline>
      <div className="text-center">
        {scheduledDate && difference !== null && difference > 0 ? (
          <>
            <Paragraph className="mb-0">{translate('hint.gameStartIn')}</Paragraph>
            <CountdownRoll className="mb-8 text-xl" difference={difference} />
          </>
        ) : (
          <Paragraph className="mb-8">{translate('hint.waitingForHost')}</Paragraph>
        )}
      </div>
      <div className="flex flex-wrap justify-center gap-2">
        {players.map((_player) => (
          <div
            key={_player.id}
            className={classNames('rounded-full px-2 font-bold text-white', {
              'bg-primary-500': _player.id === player?.id,
              'bg-gray-900': _player.id !== player?.id,
            })}
          >
            {_player?.name}
          </div>
        ))}
      </div>
    </Container>
  );
};

const RunningPartial = (game: GameContentProps) => {
  const [score, setScore] = useState<number | null>(null);
  const [lastScore, setLastScore] = useState<number>(0);
  const [confetti, setConfetti] = useState(0);
  const { activeSlot, totalQuestions, currentQuestion, answers } = game;
  const { timeLimit, timeLeft, timeIsUp } = useTimerContext();
  const { translate } = useTranslation();

  const updatePlayerTimerState = api.player.timerState.useMutation();

  useEffect(() => {
    if (answers) {
      const newScore = Object.values(answers).reduce((acc, answer) => acc + (answer.points || 0), 0);
      setScore(newScore);

      setTimeout(() => {
        setLastScore(newScore);

        if (score !== null && newScore > score) {
          setConfetti(confetti + 1);
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  useEffect(() => {
    if (timeIsUp) {
      updatePlayerTimerState.mutate({
        id: game.id,
        state: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeIsUp]);

  if (!activeSlot) return null;

  const data = activeSlot?.question;

  return (
    <div
      className={classNames('min-h-dyn flex flex-col pt-8 pb-4 transition-colors duration-1000 overflow-x-hidden', {
        'bg-gray-900': activeSlot.type === SlotType.Break,
      })}
    >
      <Container className="relative flex grow flex-col px-4" mobile>
        <ReactCanvasConfetti
          className="pointer-events-none absolute -top-8 -right-16 z-0 h-72 w-72"
          colors={['#44aac4', '#44aac4', '#44aac4', '#aeaeae', '#dcdcdc', '#696969']}
          particleCount={25}
          gravity={0.2}
          spread={40}
          angle={230}
          scalar={1}
          width={288}
          height={288}
          startVelocity={12}
          decay={0.93}
          drift={-0.5}
          origin={{ x: 1, y: -0.2 }}
          fire={confetti}
        />
        <div
          className={classNames('mb-4 flex justify-between text-xl font-black transition-colors duration-1000', {
            'text-white': activeSlot.type === SlotType.Break,
          })}
        >
          {totalQuestions && currentQuestion && (
            <div>
              {currentQuestion} / {totalQuestions}
            </div>
          )}
          <div className="tabular-nums">
            {translate('score')}: <CountUp start={lastScore} end={score || 0} duration={1} useEasing />
          </div>
        </div>
        <div
          className={classNames('mb-12 h-1 transition-all duration-1000 ease-linear', {
            'bg-gray-900': !activeSlot.withAnswer && timeLimit && timeLeft && timeLeft > timeLimit * 0.25,
            'bg-red-500':
              !activeSlot.withAnswer && timeLimit && (timeLeft || timeLeft === 0) && timeLeft <= timeLimit * 0.25,
            'bg-transparent': activeSlot.withAnswer,
          })}
          style={{
            width: timeLimit && (timeLeft || timeLeft === 0) ? `${((timeLeft - 1) / (timeLimit - 2)) * 100}%` : 0,
          }}
        />
        <AnimatePresence mode="wait" initial={false}>
          <motion.div key={activeSlot.id} className="flex grow flex-col" {...motionProps}>
            <Headline
              className={classNames('transition-colors duration-1000', {
                'mt-36 !text-6xl !text-white': activeSlot.type === SlotType.Break,
              })}
              size="h2"
            >
              {activeSlot.type === SlotType.Break ? activeSlot.title : data?.question}
            </Headline>
            {/* {activeSlot?.question?.asset &&
              activeSlot?.question?.questionType !== QuestionType.Geo &&
              activeSlot?.question?.questionType !== QuestionType.Unscramble && (
                <Image
                  className="mx-auto mb-12"
                  src={getAssetUrl(activeSlot.question.asset)}
                  width={768}
                  height={768}
                  sizes="(min-width: 960px) 768px, 100vw"
                  loader={loader}
                  alt=""
                />
              )} */}
            {activeSlot.type === SlotType.Question && (
              <div className="flex grow flex-col justify-end">
                <InteractiveFrame {...game} />
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </Container>
    </div>
  );
};

const InteractiveFrame = (game: GameContentProps) => {
  const { id, activeSlot, answers } = game;

  if (activeSlot?.type !== SlotType.Question) return null;

  const apiUtils = api.useContext();

  const giveAnswerMutation = api.player.giveAnswer.useMutation({
    onSuccess: () => {
      apiUtils.player.getGame.invalidate({ id });
    },
  });

  const handleGiveAnswer = (answer: GivenAnswer['answer']) => {
    giveAnswerMutation.mutate({
      id,
      slotId: activeSlot?.id || '',
      answer,
    });
  };

  const partialData = {
    slot: activeSlot,
    content: activeSlot?.question?.content,
    correctAnswer: activeSlot?.question.content,
    givenAnswer: answers?.[activeSlot.id] as GivenAnswer,
    onSubmit: handleGiveAnswer,
  };

  switch (activeSlot?.question?.questionType) {
    case QuestionType.Choice:
      return <ChoicePartial slotId={partialData.slot.id} withAnswer={partialData.slot.withAnswer} {...partialData} />;
    case QuestionType.Open:
      return <OpenPartial slotId={partialData.slot.id} withAnswer={partialData.slot.withAnswer} {...partialData} />;
    case QuestionType.Closest:
      return <ClosestPartial slotId={partialData.slot.id} withAnswer={partialData.slot.withAnswer} {...partialData} />;
    case QuestionType.Geo:
      return <GeoPartial slotId={partialData.slot.id} withAnswer={partialData.slot.withAnswer} {...partialData} />;
    case QuestionType.Unscramble:
      return (
        <UnscramblePartial slotId={partialData.slot.id} withAnswer={partialData.slot.withAnswer} {...partialData} />
      );
    default:
      return null;
  }
};

const FinishedPartialProvider = ({ id }: GameContentProps) => {
  const { data } = api.player.getPlayers.useQuery({ id });

  const players = data
    ?.map((player) => {
      const score = Object.values(player.answers || {}).reduce((acc, answer) => acc + (answer.points || 0), 0);

      return {
        player,
        score,
      };
    })
    .sort((a, b) => b.score - a.score);

  return <FinishedPartial players={players} />;
};

type FinishedPartialProps = {
  players?: {
    player: {
      id: string;
      name: string;
    };
    score: any;
  }[];
};

export const FinishedPartial = ({ players }: FinishedPartialProps) => {
  const { translate } = useTranslation();

  return (
    <div className="mx-auto max-w-md py-8 px-4">
      <AnimatePresence>
        <motion.div
          key="header"
          className="flex h-full flex-col items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <LogoStacked className="mx-auto mb-12 w-64" animated />
          <Headline className="mb-8 text-center">{translate('finalScore')}</Headline>
        </motion.div>
        {players && players.length > 0 && (
          <motion.div
            key="scoreboard"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 2 } }}
            exit={{ opacity: 0 }}
          >
            <table className="w-full">
              <thead className="sticky top-0 bg-gray-100">
                <tr>
                  <th className="p-2 text-left">{translate('name')}</th>
                  <th className="w-16 p-2 text-left">{translate('score')}</th>
                </tr>
              </thead>
              <tbody>
                {players?.map(({ player, score }) => (
                  <tr
                    key={player.id}
                    className={classNames('border-b border-gray-200 last-of-type:border-none', {
                      'text-green-500': score === players[0]?.score,
                    })}
                  >
                    <td className="p-2">{player.name}</td>
                    <td className="p-2">{score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const AbortedPartial = () => {
  const { translate } = useTranslation();

  return (
    <div className="mx-auto max-w-md py-8 px-4">
      <motion.div
        className="flex h-full flex-col items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <LogoStacked className="mx-auto mb-12 w-64" animated />
        <Headline size="h3" className="mb-8 text-center">
          {translate('hint.abortedGame')}
        </Headline>
      </motion.div>
    </div>
  );
};

export default Game;
