import bbox from '@turf/bbox';
import { points as turfPoints } from '@turf/helpers';
import maplibregl from 'maplibre-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Layer, LineLayer, MapLayerMouseEvent, MapRef, Marker, Source } from 'react-map-gl';

import { MarkerPinSolid } from '../misc/icons';

const getLayerStyle = (visible?: boolean): LineLayer => {
  return {
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#fff',
      'line-width': 4,
      'line-dasharray': [1, 2.5],
      'line-opacity': visible ? 1 : 0,
      'line-opacity-transition': {
        duration: visible ? 1000 : 300,
        delay: 0,
      },
    },
  };
};

export type CoordinatesProps = {
  lat: number;
  lng: number;
};

export type MarkerProps = {
  lat?: number;
  lng?: number;
};

type MapProps = {
  target?: {
    lat?: number;
    lng?: number;
  };
  marker?: MarkerProps;
  onPlaceMarker: (value: MarkerProps) => void;
  showTarget?: boolean;
  disabled?: boolean;
  className?: string;
};

const Map = ({ target, marker, onPlaceMarker, showTarget, disabled, className }: MapProps) => {
  // TODO: add proper types
  const [geoJson, setGeoJson] = useState<any>({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
    properties: {},
  });
  const [viewState, setViewState] = useState<any>({
    lat: 0,
    lng: 0,
    zoom: 0,
  });

  const mapRef = useRef<MapRef>();

  useEffect(() => {
    if (!showTarget) {
      setGeoJson(null);
      onPlaceMarker({ ...marker });

      return;
    }

    if (!mapRef.current || target?.lat === undefined || target?.lng === undefined) {
      return;
    }

    if (marker?.lat === undefined || marker?.lng === undefined) {
      mapRef.current.flyTo({ center: [target.lng, target.lat], zoom: 5, duration: 3000 });
      return;
    }

    // calculate bounding box
    const feature = turfPoints([
      [Math.min(marker!.lng, target.lng), Math.min(marker!.lat, target.lat)],
      [Math.max(marker!.lng, target.lng), Math.max(marker!.lat, target.lat)],
    ]);
    const [minLng, minLat, maxLng, maxLat] = bbox(feature);

    mapRef.current.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 60, duration: 3000 }
    );

    // set line between marker and target
    setGeoJson({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [marker!.lng, marker!.lat],
          [target.lng, target.lat],
        ],
      },
    });

    onPlaceMarker(marker);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTarget]);

  const handleSetMarker = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!showTarget && !disabled) {
        const coords = {
          lng: e.lngLat.lng,
          lat: e.lngLat.lat,
        };
        onPlaceMarker(coords);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showTarget]
  );

  return (
    <div className={className}>
      <ReactMapGL
        {...viewState}
        ref={mapRef}
        mapLib={maplibregl}
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, cursor: 'default' }}
        mapStyle="/map/styles/quizzlybear/style.json"
        baseApiUrl="/map"
        onMove={(evt) => setViewState(evt.viewState)}
        onClick={handleSetMarker}
        dragRotate={false}
        touchPitch={false}
        pitchWithRotate={false}
        attributionControl={false}
        RTLTextPlugin={false}
      >
        <Source id="data" type="geojson" data={geoJson}>
          <Layer {...getLayerStyle(showTarget)} />
        </Source>

        {marker?.lat !== undefined && marker?.lng !== undefined && (
          <Marker latitude={marker!.lat} longitude={marker!.lng} offset={[0, -12]}>
            <MarkerPinSolid className="pointer-events-none text-2xl text-primary-500 drop-shadow-lg filter" />
          </Marker>
        )}
        {showTarget && target?.lat !== undefined && target?.lng !== undefined && (
          <Marker latitude={target.lat} longitude={target.lng} offset={[0, -12]}>
            <MarkerPinSolid className="fade-in pointer-events-none text-2xl text-green-500 drop-shadow-lg filter" />
          </Marker>
        )}
      </ReactMapGL>
    </div>
  );
};

export default Map;
