export enum ErrorCode {
  SomethingWentWrong = '[100] Something went wrong',
  UniqueConstraintViolation = '[101] Unique constraint violation.',
  InvalidCredentials = '[102] Invalid credentials.',
  NotFound = '[103] Not found.',
  Unauthorized = '[104] Unauthorized.',
  MissingRequiredData = '[105] Missing required data.',
  Forbidden = '[106] Forbidden.',
  EmailNotVerified = '[107] Email not verified.',
  InvalidToken = '[108] Invalid token.',
  TokenExpired = '[109] Token expired.',
}
