import { createContext, useContext, useEffect, useRef, useState } from 'react';

interface UseTimerProps {
  timestamp?: number;
  timeLimit?: number;
  started?: boolean;
  children?: React.ReactNode;
}

interface UseTimerResult {
  timeLimit: number | undefined;
  timeLeft: number | null;
  timeIsUp: boolean;
  stopTimer: () => void;
}

const TimerContext = createContext<UseTimerResult | null>(null);

export const useTimer = ({ timestamp, timeLimit, started }: UseTimerProps = {}): UseTimerResult => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [timeIsUp, setTimeIsUp] = useState(!timestamp && !!timeLimit && !!started);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const stopTimer = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
    setTimeIsUp(true);
    setTimeLeft(null);
  };

  useEffect(() => {
    if (timestamp && timeLimit && started) {
      setTimeIsUp(false);

      const endTime = timestamp + timeLimit * 1000;

      intervalRef.current = setInterval(() => {
        const timeRemaining = Math.round((endTime - Date.now()) / 1000);
        setTimeLeft(timeRemaining);
        if (timeRemaining <= 0) {
          stopTimer();
        }
      }, 1000);
    } else if (timeLimit && started) {
      stopTimer();
    } else {
      setTimeIsUp(false);
      setTimeLeft(null);
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [timestamp, timeLimit, started]);

  return { timeLimit, timeLeft, timeIsUp, stopTimer };
};

export const TimerProvider = ({ timestamp, timeLimit, started, children }: UseTimerProps) => {
  const timer = useTimer({ timestamp, timeLimit, started });

  return <TimerContext.Provider value={timer}>{children}</TimerContext.Provider>;
};

export const useTimerContext = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error('useTimerContext must be used within a TimerProvider');
  }
  return context;
};
