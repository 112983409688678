import classNames from 'classnames';

type LabelProps = {
  htmlFor: string;
  required?: boolean;
  children: React.ReactNode;
};

const Label = ({ htmlFor, required, children }: LabelProps) => {
  return (
    <label
      className={classNames(
        'absolute left-2 right-4 top-0 -translate-y-5 translate-x-0 select-none truncate text-sm font-semibold text-gray-500 transition-all',
        'peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:translate-x-2 peer-placeholder-shown:cursor-text peer-placeholder-shown:text-lg',
        'peer-focus:-translate-y-5 peer-focus:translate-x-0 peer-focus:text-sm'
      )}
      htmlFor={htmlFor}
    >
      {children}
      {/* // TODO: do we want this..? */}
      {/* {required && ' *'} */}
    </label>
  );
};

export default Label;
