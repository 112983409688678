import classNames from 'classnames';
import { useTimerContext } from 'lib/hooks/timer';
import { useEffect, useState } from 'react';
import { PartialProps } from 'src/types';

import WordRoll from '../ui/CharacterRoll/WordRoll';

const UnscramblePartial = ({ withAnswer, content, onSubmit }: PartialProps) => {
  const [answered, setAnswered] = useState<boolean>(false);
  const { timeIsUp } = useTimerContext();
  const [order, setOrder] = useState<({ letter: string; origin: number | null } | null)[]>(
    Array(content?.scrambled?.length).fill(null)
  );

  const removeLetter = (index: number) => {
    const newOrder = [...order];
    newOrder[index] = null;

    setOrder(newOrder);
  };

  const addLetter = (index: number) => {
    if (!content?.scrambled?.[index]) return;

    const newOrder = [...order];
    const emptyIndex = newOrder.findIndex((e) => e === null);

    newOrder[emptyIndex] = {
      letter: content.scrambled[index] || '',
      origin: index,
    };

    setOrder(newOrder);
  };

  useEffect(() => {
    if (order.every((e) => e !== null)) {
      const answer = order.map((e) => e?.letter).join('');

      onSubmit(answer);
      setAnswered(true);
      return;
    }

    if (order.some((e) => e === null) && answered) {
      onSubmit('');
      setAnswered(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <div>
      <WordRoll
        word={(withAnswer ? content?.answer : content?.scrambled) as string}
        className="mb-16 text-2xl"
        duration={3}
      />
      <div className="mb-8 flex justify-center gap-2">
        {content?.scrambled?.split('').map((letter, index) => (
          <button
            key={index}
            className={classNames(
              'flex h-16 w-12 items-center justify-center rounded-xl bg-gray-100 text-2xl font-bold transition-colors delay-[3s] duration-500',
              {
                'text-gray-900': !withAnswer,
                'text-green-500': withAnswer && order[index]?.letter === (content?.answer as string)[index],
                'text-red-500': withAnswer && order[index]?.letter !== (content?.answer as string)[index],
              }
            )}
            onClick={() => removeLetter(index)}
            disabled={timeIsUp || withAnswer || order[index] === null}
          >
            {order[index]?.letter}
          </button>
        ))}
      </div>

      <div className="flex justify-center gap-2">
        {content?.scrambled?.split('').map((letter, index) => (
          <button
            key={index}
            className={classNames(
              'flex h-16 w-12 items-center justify-center rounded-xl bg-gray-900 text-2xl font-bold text-white transition-colors disabled:bg-gray-700 hover:bg-gray-800 disabled:hover:bg-gray-700',
              {
                'text-opacity-50': withAnswer || order.find((e) => e?.origin === index) !== undefined,
              }
            )}
            onClick={() => addLetter(index)}
            disabled={timeIsUp || withAnswer || order.find((e) => e?.origin === index) !== undefined}
          >
            {letter}
          </button>
        ))}
      </div>
    </div>
  );
};

export default UnscramblePartial;
