import classNames from 'classnames';
import { forwardRef, HTMLProps, Ref } from 'react';
import { Icon } from 'src/types';

import FieldErrorMessage from './FieldErrorMessage';
import Label from './Label';

type InputProps = HTMLProps<HTMLInputElement> & {
  name: string;
  label?: string;
  icon?: Icon;
  fieldError?: string;
  inputClassName?: string;
};

const Input = (
  {
    name,
    label,
    type = 'text',
    required = false,
    id,
    className,
    inputClassName,
    disabled,
    icon: Icon,
    fieldError,
    ...inputProps
  }: InputProps,
  ref: Ref<HTMLInputElement>
) => {
  const inputId = id || `input-${name}`;

  return (
    <div className={classNames('relative mt-8', className)}>
      <input
        {...inputProps}
        ref={ref}
        name={name}
        id={inputId}
        type={type}
        className={classNames(
          'peer w-full rounded-xl bg-gray-200 py-2.5 pl-4 text-lg font-semibold text-gray-900 placeholder:opacity-0 focus-visible:outline-none disabled:cursor-default',
          {
            'pr-4': !Icon,
            'pr-12': Icon,
          },
          inputClassName
        )}
        disabled={disabled}
        aria-invalid={!!fieldError}
        placeholder={label}
      />
      {label && (
        <Label htmlFor={inputId} required={required}>
          {label}
        </Label>
      )}
      {Icon && <Icon className="absolute top-3.5 right-4 text-xl text-gray-500" />}

      {fieldError && <FieldErrorMessage message={fieldError} />}
    </div>
  );
};

export default forwardRef(Input);
