import { useTranslation } from 'lib/translation/index';
import { HTMLProps } from 'react';
import type { FieldPath, FieldValues, FormState, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { get } from 'react-hook-form';

import PasswordInput from '../ui/form/PasswordInput';

type FormPasswordInputProps<TFieldValues extends FieldValues> = HTMLProps<HTMLInputElement> & {
  name: FieldPath<TFieldValues>;
  label?: string;
  register: UseFormRegister<TFieldValues>;
  registerOptions?: RegisterOptions;
  formState: FormState<TFieldValues>;
  required?: boolean;
};

const FormPasswordInput = <TFieldValues extends FieldValues>({
  name,
  label,
  register,
  registerOptions,
  formState: { errors },
  required = false,
  ...inputProps
}: FormPasswordInputProps<TFieldValues>) => {
  const { translate } = useTranslation();
  const fieldError = get(errors, name);

  return (
    <PasswordInput
      {...inputProps}
      {...register(name, { required, ...registerOptions })}
      label={label}
      required={required}
      fieldError={fieldError?.message && translate(`form.fieldError.${fieldError.message}`)}
    />
  );
};

export default FormPasswordInput;
