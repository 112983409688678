import classNames from 'classnames';
import { motion } from 'framer-motion';
import { calculateDistance } from 'lib/helper/data';
import { useTimerContext } from 'lib/hooks/timer';
import { useRef, useState } from 'react';
import CountUp from 'react-countup';
import { PartialProps } from 'src/types';

import Map, { MarkerProps } from '../ui/Map';

const GeoPartial = ({ withAnswer, content, givenAnswer, onSubmit }: PartialProps) => {
  const { timeIsUp } = useTimerContext();

  // workaround to access timeIsUp in handleSubmit
  const timeIsUpRef = useRef<boolean>();
  timeIsUpRef.current = timeIsUp;

  const answer = givenAnswer?.answer as MarkerProps | undefined;

  const [marker, setMarker] = useState<MarkerProps>({
    lat: answer?.lat,
    lng: answer?.lng,
  });

  const handleSubmit = ({ lat, lng }: MarkerProps) => {
    if (timeIsUpRef.current || lat === undefined || lng === undefined) return;

    setMarker({ lat, lng });

    onSubmit({
      lat,
      lng,
    });
  };

  return (
    <>
      {withAnswer &&
        marker.lat !== undefined &&
        marker.lng !== undefined &&
        content?.lat !== undefined &&
        content?.lng !== undefined && (
          <motion.div className="" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div
              className={classNames('text-center text-4xl font-black tabular-nums transition-colors delay-[3000]', {
                'fade-green [animation-delay:4s]': givenAnswer?.points !== undefined && givenAnswer.points > 0,
                'text-red-500': givenAnswer?.points !== undefined && givenAnswer.points <= 0,
              })}
            >
              <span>
                <CountUp
                  start={0}
                  end={calculateDistance(marker.lat, marker.lng, content?.lat, content?.lng) / 1000}
                  decimals={2}
                  delay={1}
                  duration={3}
                  useEasing
                />
              </span>{' '}
              km
            </div>
          </motion.div>
        )}
      <div className="relative mt-8 w-full grow">
        <Map
          className="absolute inset-0 overflow-hidden rounded-xl"
          target={{
            lat: content?.lat,
            lng: content?.lng,
          }}
          onPlaceMarker={(marker) => !timeIsUp && !withAnswer && handleSubmit(marker)}
          marker={marker}
          showTarget={withAnswer}
        />
      </div>
    </>
  );
};

export default GeoPartial;
