import { useTranslation } from 'lib/translation/index';
import { HTMLProps } from 'react';
import type { FieldPath, FieldValues, FormState, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { get } from 'react-hook-form';
import { Icon } from 'src/types';

import Input from '../ui/form/Input';

type FormInputProps<TFieldValues extends FieldValues> = HTMLProps<HTMLInputElement> & {
  name: FieldPath<TFieldValues>;
  label?: string;
  register: UseFormRegister<TFieldValues>;
  registerOptions?: RegisterOptions;
  formState: FormState<TFieldValues>;
  required?: boolean;
  icon?: Icon;
  inputClassName?: string;
};

const FormInput = <TFieldValues extends FieldValues>({
  name,
  label,
  register,
  registerOptions,
  formState: { errors },
  required = false,
  icon,
  inputClassName,
  ...inputProps
}: FormInputProps<TFieldValues>) => {
  const { translate } = useTranslation();
  const fieldError = get(errors, name);

  return (
    <Input
      {...inputProps}
      {...register(name, { required, ...registerOptions })}
      label={label}
      required={required}
      fieldError={fieldError?.message && translate(`form.fieldError.${fieldError.message}`)}
      icon={icon}
      inputClassName={inputClassName}
    />
  );
};

export default FormInput;
