import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useTimerContext } from 'lib/hooks/timer';
import { useTranslation } from 'lib/translation';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { PartialProps } from 'src/types';
import useConstant from 'use-constant';

import Input from '../ui/form/Input';
import Headline from '../ui/Headline';
import Paragraph from '../ui/Paragraph';

const ClosestPartial = ({ withAnswer, content, givenAnswer, onSubmit }: PartialProps) => {
  const { translate } = useTranslation();
  const [answer, setAnswer] = useState<number | undefined>();
  const { timeIsUp } = useTimerContext();

  const debouncedSubmit = useConstant(() => AwesomeDebouncePromise(onSubmit, 300));

  const answerMotionProps = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  };

  const handleTypeAnswer = (value: string) => {
    // regex that allows negative and decimal values, but max 3 decimal places
    const regex = /^-?\d*(\.\d{0,3})?$/;

    if (value === '') {
      setAnswer(undefined);
      debouncedSubmit('');
    }

    if (regex.test(value)) {
      const answer = parseFloat(value);
      setAnswer(answer);
      debouncedSubmit(answer);
    }
  };

  useEffect(() => {
    if ((answer || answer === 0) && (timeIsUp || withAnswer) && !(givenAnswer?.answer || givenAnswer?.answer === 0)) {
      onSubmit(answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeIsUp, withAnswer]);

  return (
    <AnimatePresence mode="wait" initial={false}>
      {withAnswer && content?.answer && (
        <motion.div key="solution" {...answerMotionProps}>
          <div className="mb-36 text-center">
            <div className="text-center text-4xl font-black tabular-nums text-green-500">
              <span>
                <CountUp
                  start={0}
                  end={content.answer as number}
                  delay={1}
                  duration={3}
                  decimals={
                    // get number of decimals of answer
                    (content.answer as number).toString().split('.')[1]?.length || 0
                  }
                  useEasing
                />
              </span>
              {content?.unit && <span> {content.unit}</span>}
            </div>
          </div>
        </motion.div>
      )}
      {!withAnswer && !timeIsUp && (
        <motion.div key="input" {...answerMotionProps}>
          <div className="mb-4 flex items-center gap-4">
            <Input
              name="answer"
              type="number"
              step=".001"
              className="!m-0 grow"
              label={translate('form.question.answer')}
              value={answer || ''}
              disabled={timeIsUp || withAnswer}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTypeAnswer(e.target.value)}
            />
            {content?.unit && <div className="text-xl font-bold">{content.unit}</div>}
          </div>
        </motion.div>
      )}
      {(timeIsUp || withAnswer) && givenAnswer?.answer && (
        <motion.div key="answer" {...answerMotionProps}>
          <Paragraph className="mb-0">{translate('yourAnswer')}</Paragraph>
          <Headline
            className={classNames('transition-colors', {
              'text-green-500': givenAnswer?.points !== undefined && givenAnswer.points > 0,
            })}
            size="h3"
          >
            <>
              {givenAnswer.answer}
              {content?.unit && ` ${content.unit}`}
            </>
          </Headline>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ClosestPartial;
