import classNames from 'classnames';

type FieldErrorMessageProps = {
  message: string;
  className?: string;
};

const FieldErrorMessage = ({ message, className }: FieldErrorMessageProps) => {
  return (
    <p className={classNames('ml-2 mt-1.5 text-sm font-semibold text-red-500', className)} title={message}>
      {message}
    </p>
  );
};

export default FieldErrorMessage;
