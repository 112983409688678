import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useTimerContext } from 'lib/hooks/timer';
import { useTranslation } from 'lib/translation';
import { useEffect, useState } from 'react';
import { PartialProps } from 'src/types';
import useConstant from 'use-constant';

import Input from '../ui/form/Input';
import Headline from '../ui/Headline';
import Paragraph from '../ui/Paragraph';

const OpenPartial = ({ withAnswer, content, givenAnswer, onSubmit }: PartialProps) => {
  const { translate } = useTranslation();
  const [answer, setAnswer] = useState('');
  const { timeIsUp } = useTimerContext();

  const debouncedSubmit = useConstant(() => AwesomeDebouncePromise(onSubmit, 300));

  const answerMotionProps = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  };

  const handleTypeAnswer = (value: string) => {
    setAnswer(value);
    debouncedSubmit(value);
  };

  useEffect(() => {
    if ((timeIsUp || withAnswer) && !(givenAnswer?.answer || givenAnswer?.answer === '')) {
      onSubmit(answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeIsUp, withAnswer]);

  return (
    <AnimatePresence mode="wait" initial={false}>
      {withAnswer && content?.answer && (
        <motion.div key="solution" {...answerMotionProps}>
          <Headline className="!mb-36 text-center text-green-500" size="h2">
            {content.answer}
          </Headline>
        </motion.div>
      )}
      {!withAnswer && !timeIsUp && (
        <motion.div key="input" {...answerMotionProps}>
          <Input
            name="answer"
            className="mb-4"
            label={translate('form.question.answer')}
            value={answer}
            disabled={timeIsUp || withAnswer}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTypeAnswer(e.target.value)}
          />
        </motion.div>
      )}
      {(timeIsUp || withAnswer) && givenAnswer?.answer && (
        <motion.div key="answer" {...answerMotionProps}>
          <Paragraph className="mb-0">{translate('yourAnswer')}</Paragraph>
          <Headline
            className={classNames('transition-colors', {
              'text-green-500': givenAnswer?.points !== undefined && givenAnswer.points > 0,
            })}
            size="h3"
          >
            {givenAnswer.answer as string}
          </Headline>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OpenPartial;
